import React from 'react';
import FullPage from './fullpage';
import LogoGrid from './logo-grid';
import Presentation from './presentation';
import Cast from './cast';
import Grid7 from './grid-7';
import Grid12 from './grid-12';
import Grid14 from './grid-14';
import Grid16 from './grid-16';
import Grid18 from './grid-18';
import Grid19 from './grid-19';
import Grid21 from './grid-21';
import Grid23 from './grid-23';
import StoryBoard from './storyboard';

const ComponentSelector = ({data}) => {
    console.log(data)
    const {component} = data;
    console.log('component', component)
    if (component==='fullpage') return <FullPage data={data} />
    if (component==='logogrid') return <LogoGrid data={data} />
    if (component==='presentation') return <Presentation data={data} />
    if (component==='cast') return <Cast data={data} />
    if (component==='storyboard') return <StoryBoard data={data} />
    if (component==='grid7') return <Grid7 data={data} />
    if (component==='grid12') return <Grid12 data={data} />
    if (component==='grid14') return <Grid14 data={data} />
    if (component==='grid16') return <Grid16 data={data} />
    if (component==='grid18') return <Grid18 data={data} />
    if (component==='grid19') return <Grid19 data={data} />
    if (component==='grid21') return <Grid21 data={data} />
    if (component==='grid23') return <Grid23 data={data} />
    return (
        <div>{component} not found!</div>
    )    
}

export default ComponentSelector;