import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { InView } from "react-intersection-observer";
import { TweenMax } from "gsap/TweenMax";
import { media } from '../utils/media';

const LogoGrid = ({data}) => {
    const [elements, setElements] = useState([]);

    const pushElem = (e) => {
        if (e) {
            let tmpElem = elements;
            tmpElem.push(e);
            setElements(tmpElem);
        }
    }

    const animateComponent = e => {
        if (e) {
            TweenMax.set(elements, {
                scale: 0,
                alpha: 0
            });
            TweenMax.staggerTo(
                elements,
                0.6,
                {
                    scale: 1,
                    alpha: 1,
                    delay: 0
                },
                0.2
            );
        }
      };

      useEffect(()=>{
        TweenMax.set(elements, {
          x: -10,
          alpha: 0
        });
      }, [elements])

    return (
        <InView onChange={animateComponent} triggerOnce={true} threshold={0.1}>
            <LogoGridStyle>
                {data.logo.map((e, i) => {
                    return (
                        <Logo color={e.color} width={e.width} >
                            <img src={`images/${e.image}`} alt="" ref={e => pushElem(e)} style={{opacity: 0}} />
                        </Logo>
                    )
                })}
            </LogoGridStyle>
        </InView>
    )
}

export default LogoGrid

const LogoGridStyle = styled.div`
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    ${media.phone`
        height: unset;
    `}
`
const Logo = styled.div`
    width: 25%;
    height: 50%;
    background-color: ${props => props.color ? props.color : '#ffffff'};
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: ${props => props.width ? props.width : '70%'};
        ${media.phone`
        max-height: 70%;
        `}
    }
    ${media.phone`
        width: 100%;
        height: 400px;
    `}
`