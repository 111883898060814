import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { InView } from "react-intersection-observer";
import { TweenMax } from "gsap/TweenMax";
import { media } from "../utils/media";

const Grid19 = ({data}) => {
    const [elements, setElements] = useState([]);

    const pushElem = (e) => {
        if (e) {
            let tmpElem = elements;
            tmpElem.push(e);
            setElements(tmpElem);
        }
    }

    const animateComponent = e => {
        if (e) {
            TweenMax.set(elements, {
                x: -30,
                alpha: 0
            });
            TweenMax.staggerTo(
                elements,
                0.8,
                {
                    x: 0,
                    alpha: 1,
                    delay: 0
                },
                0.1
            );
        }
      };
      useEffect(()=>{
        TweenMax.set(elements, {
          x: -10,
          alpha: 0
        });
      }, [elements])

    return (
        <InView onChange={animateComponent} triggerOnce={true} threshold={0.2}>
            <Grid backgroundcolor={data.backgroundcolor} textcolor={data.textcolor}>
                <div className="grid-wrapper" >
                    <h1 ref={e => pushElem(e)}>{data.header}</h1>
                    <div className="wrapper">
                        <GridStyle>
                            {data.images.map((e,i)=>{
                                return <Item image={e.image} className="item" ref={e => pushElem(e)} />
                            })}
                        </GridStyle>
                    </div>
                    
                </div>
            </Grid>
        </InView>
    )
}

export default Grid19;

const Grid = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: ${props => props.backgroundcolor};
    position: relative;
    ${media.phone`
    height: unset;
    `}
    div.grid-wrapper {
        width: 90%;
        height: 90%;
        position: relative;
        align-items: flex-end;
        display: flex;
        flex-wrap: wrap;
        h1 {
            position: absolute;
            top: 0;
            left: 0;
            color: ${props => props.textcolor ? props.textcolor : "#ffffff"};
        }
        
        .wrapper {
            height: calc(100% - 90px);
            width: 100%;
            display: flex;
            position: relative;
            ${media.phone`
            margin-top: 100px;
            margin-bottom: 40px;
            `}
        }
        
    }
    
`

const GridStyle = styled.div`
    display: grid;
    width: 100%;
    height: calc(100% - 50px);
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 33% 33% 33%;
    grid-gap: 10px 10px;
    ${media.phone`
    grid-template-columns: 1fr;
    grid-template-rows: unset;
    `}
`

const Item = styled.div`
    background-image: url(images/${props=>props.image});
    background-position: center center;
    background-size: cover;
    &:nth-child(1) {
        grid-row: 1 / 4;
        ${media.phone`
        grid-row: unset;
        `}
    }
    &:nth-child(2) {
        grid-row: 1 / 4;
        ${media.phone`
        grid-row: unset;
        `}
    }
    &:nth-child(3) {
        grid-row: 1 / 3;
        ${media.phone`
        grid-row: unset;
        `}
    }
    &:nth-child(4) {
        grid-row: 1 / 3;
        ${media.phone`
        grid-row: unset;
        `}
    }
    ${media.phone`
    min-height: 200px;
    `}
`