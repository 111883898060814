import React, {useState, useEffect} from 'react';
import "./index.css"
import ComponentSelector from './components/component-selector';

function App() {
  const [data, setData] = useState([]);

  useEffect(()=>{
    if (data.length === 0) {
      fetch("./data.json") // Call the fetch function passing the url of the API as a parameter
      .then((resp) => resp.json())
      .then(function(data) {
          setData(data)
      });
    }
    
  })

  return (
    <div className="App">
      {data.map((e,i) => <ComponentSelector key={`component-${i}`} data={e} />)}
    </div>
  );
}

export default App;
