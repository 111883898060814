import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { InView } from "react-intersection-observer";
import { TweenMax } from "gsap/TweenMax";
import { media } from '../utils/media';

const FullPage = ({data}) => {
    const [elements, setElements] = useState([]);

    const pushElem = (e) => {
        if (e) {
            let tmpElem = elements;
            tmpElem.push(e);
            setElements(tmpElem);
        }
    }

    const animateComponent = e => {
        if (e) {
            TweenMax.set(elements, {
                x: -30,
                alpha: 0
            });
            TweenMax.staggerTo(
                elements,
                1,
                {
                    x: 0,
                    alpha: 1,
                    delay: 0
                },
                0.3
            );
        }
      };
      useEffect(()=>{
        TweenMax.set(elements, {
          x: -10,
          alpha: 0
        });
      }, [elements])
    return (
        <InView onChange={animateComponent} triggerOnce={true} threshold={0.2}>
            <FullPageContainer backgroundColor={data.backgroundcolor} textcolor={data.textcolor}>
                <div className="flex">
                    <div className="image-container"><img src={`images/${data.image}`} alt="" ref={e => pushElem(e)} /></div>
                    {data.text && (
                        <div className="text" ref={e => pushElem(e)}>{data.text}</div>
                    )}
                </div>
                
            </FullPageContainer>
        </InView>
    )
}

export default FullPage

const FullPageContainer = styled.div`
    height: 100vh;
    min-height: 350px;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : '#ffffff'};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    ${media.phone`
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px 0px;
    `}
    .flex {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 80%;
        .image-container {
            flex: 1;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
            img {
                height: 200px;
                max-width: 80%;
            }
        }
    }
    
    .text {
        /* position: absolute;
        bottom: 80px; */
        color: ${props => props.textcolor};
        font-size: 20px;
        max-width: 100%;
        text-align: center;
        ${media.phone`
            position: unset;
            max-width: 80%;
            text-align: center;
        `}
    }
`