import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { InView } from "react-intersection-observer";
import { TweenMax } from "gsap/TweenMax";
import { media } from "../utils/media";

const Cast = ({data}) => {
    const [elements, setElements] = useState([]);

    const pushElem = (e) => {
        if (e) {
            let tmpElem = elements;
            tmpElem.push(e);
            setElements(tmpElem);
        }
    }

    const animateComponent = e => {
        if (e) {
            TweenMax.set(elements, {
                scale: 0,
                alpha: 0
            });
            TweenMax.staggerTo(
                elements,
                0.6,
                {
                    scale: 1,
                    alpha: 1,
                    delay: 0
                },
                0.2
            );
        }
      };

      useEffect(()=>{
        TweenMax.set(elements, {
          scale: 0,
          alpha: 0
        });
      }, [elements])

    return (
        <InView onChange={animateComponent} triggerOnce={true} threshold={0.1}>
            <PresentationStyle backgroundcolor={data.backgroundcolor}>
                <PresentationWrapper headercolor={data.headercolor}>
                    {data.header && (<h1 ref={e => pushElem(e)}>{data.header}</h1>)}
                    {data.text && (<div ref={e => pushElem(e)} dangerouslySetInnerHTML={{__html: data.text}} />) }
                    <div className="profile-wrapper">
                        <Grid>
                            <GridStyle>

                                {data.images.map((e,i)=>{
                                    return (
                                        
                                        <div className="profile" ref={e => pushElem(e)} style={{opacity: 0}}>
                                            <div className="image-container">
                                                <ImageStyle image={`images/${e.image}`} />
                                            </div>
                                            
                                            <div className="text-container">
                                                <div>{e.name}</div>
                                                <div>{e.plays}</div>
                                                <div>{e.desc}</div>
                                            </div>
                                        </div>
                                    
                                    )
                                })}
                                
                            </GridStyle>
                        </Grid>
                        
                    </div>
                </PresentationWrapper>
            </PresentationStyle>
        </InView>
    )
}

export default Cast

const PresentationStyle = styled.div`
    background-color: ${props => props.backgroundcolor};
    color: #ffffff;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    ${media.phone`
    height: unset;
    padding-top: 30px;
    `}
`

const ImageStyle = styled.div`
    background-image: url(${props=>props.image});
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
    background-position: center center;
`

const PresentationWrapper = styled.div`
    width: 90%;
    height: 90%;
    position: relative;
    align-items: flex-end;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    
    h1 {
        position: absolute;
        top: 0px;
        left: 0px;
        color: ${props => props.headercolor};
    }
    .profile-wrapper {
        height: calc(100% - 80px);
        width: 100%;
        display: flex;
        position: relative;
        ${media.phone`
        margin-bottom: 50px;
        margin-top: 50px;
        `}
    }
    
`

const Grid = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
`

const GridStyle = styled.div`
    display: grid;
    /* height: 90%; */
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* grid-template-rows: 1fr 1fr; */
    grid-gap: 10px 10px;
    margin-top: 60px;
    margin-bottom: 60px;
    ${media.phone`
    grid-template-columns: 1fr;
    height: unset;
    `}
    .profile {
        display: flex;
        flex-direction: column;
        text-align: center;
        color: #723e95;
        font-style: italic;
        font-size: 20px;
        div {
            &.text-container {
                margin-top: 20px;
            }
            &:first-child {
                font-style: normal;
                font-size: 25px;
                font-family: 'Circular Std';
                margin-top: 20px;
            }
        }
        .image-container {
            height: 200px;
            ${media.phone`
            height: 200px;
            `}
        }
    }
`