import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { InView } from "react-intersection-observer";
import { TweenMax } from "gsap/TweenMax";


const Grid18 = ({data}) => {
    const [elements, setElements] = useState([]);

    const pushElem = (e) => {
        if (e) {
            let tmpElem = elements;
            tmpElem.push(e);
            setElements(tmpElem);
        }
    }

    const animateComponent = e => {
        if (e) {
            TweenMax.set(elements, {
                x: 0,
                alpha: 0
            });
            TweenMax.staggerTo(
                elements,
                1,
                {
                    x: 0,
                    alpha: 1,
                    delay: 0
                },
                0.2
            );
        }
      };
      useEffect(()=>{
        TweenMax.set(elements, {
          x: -10,
          alpha: 0
        });
      }, [elements])

    return (
        <InView onChange={animateComponent} triggerOnce={true} threshold={0.2}>
            <Grid ref={e => pushElem(e)} backgroundcolor={data.backgroundcolor} textcolor={data.textcolor} backgroundimage={`images/${data.backgroundimage}`}>
                <div className="grid-wrapper">
                    <h1 ref={e => pushElem(e)}>{data.header}</h1>
                </div>
            </Grid>
        </InView>
    )
}

export default Grid18;

const Grid = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: ${props => props.backgroundcolor};
    position: relative;
    background-image: url(${props=>props.backgroundimage});
    background-size: cover;
    background-position: center center;
    div.grid-wrapper {
        width: 90%;
        height: 90%;
        position: relative;
        align-items: flex-end;
        display: flex;
        flex-wrap: wrap;
        h1 {
            position: absolute;
            top: 0;
            left: 0;
            color: ${props => props.textcolor ? props.textcolor : "#ffffff"};
        }
        
        .wrapper {
            height: calc(100% - 90px);
            width: 100%;
            display: flex;
            position: relative;
            
        }
        
    }
    
`