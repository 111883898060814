import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { InView } from "react-intersection-observer";
import { TweenMax } from "gsap/TweenMax";
import { media } from "../utils/media";

const Presentation = ({data}) => {
    const [elements, setElements] = useState([]);

    const pushElem = (e) => {
        if (e) {
            let tmpElem = elements;
            tmpElem.push(e);
            setElements(tmpElem);
        }
    }

    const animateComponent = e => {
        if (e) {
            TweenMax.set(elements, {
                x: -30,
                alpha: 0
            });
            TweenMax.staggerTo(
                elements,
                0.8,
                {
                    x: 0,
                    alpha: 1,
                    delay: 0
                },
                0.3
            );
        }
      };

      useEffect(()=>{
        TweenMax.set(elements, {
          x: -10,
          alpha: 0
        });
      }, [elements])

    return (
        <InView onChange={animateComponent} triggerOnce={true} threshold={0.2}>
            <PresentationStyle backgroundcolor={data.backgroundcolor}>
                    <PresentationWrapper headercolor={data.headercolor} alignItems={data.image ? 'flex-end' : 'center'}>
                        {data.header && (<h1 ref={e => pushElem(e)} style={{opacity: 0}}>{data.header}</h1>)}
                        <ContentWrapper>
                        {data.text && (<div ref={e => pushElem(e)} style={{opacity: 0}} className="text" dangerouslySetInnerHTML={{__html: data.text}} />) }
                        {data.image && (<img className="image" ref={e => pushElem(e)} style={{opacity: 0}} src={`images/${data.image}`} alt="" />)}
                        </ContentWrapper>
                        
                    </PresentationWrapper>
            </PresentationStyle>
        </InView>
    )
}

export default Presentation

const PresentationStyle = styled.div`
    background-color: ${props => props.backgroundcolor};
    color: #ffffff;
    height: 100vh;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    ${media.phone`
        height: unset;
        padding-top: 30px;
    `}
`

const PresentationWrapper = styled.div`
    width: 90%;
    height: 90%;
    min-height: inherit;
    position: relative;
    justify-content: start;
    display: flex;
    flex-direction: column;
    position: absolute;
    div.text {
        width: 50%;
        text-align: justify;
        text-justify: inter-word;
        margin-top: 80px;
        margin-bottom: 100px;
        ${media.phone`
            margin-top: 130px;
            width: unset;
            padding: 0px 40px 50px 40px;;
        `}
    }
    img {
        width: 100%;
    }
    h1 {
        /* position: absolute;
        top: 0px;
        left: 0px; */
        color: ${props => props.headercolor};
    }
    ${media.phone`
    height: unset;
    `}
`
const ContentWrapper = styled.div`
    width: 100%;
    height: calc(100% - 80px);
    display: flex;
    align-items: center;
    justify-content: center;
    img.image {
        width: 100%;
        max-height: 100%;
        ${media.phone`
        margin-top: 100px;
        margin-bottom 30px;
        `}
    }
`